<template>
  <v-chip
    close
    outlined
    @click.stop
    @click:close="$emit('remove', true)"
    @mousedown.stop>
    <v-text-field
      v-model="searchVal"
      :placeholder="name"
      :style="{ width: textFieldSize }"
      class="pt-0 mt-0 search-filter__textfield"
      color="black"
      hide-details
      persistent-placeholder
      @input="inputUpdate" />
    <span
      ref="contentWidth"
      class="content-width fs-42">{{ searchVal }}</span>
  </v-chip>
</template>

<script>
export default {
  name: 'SearchFilterInputText',
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      required: true,
    },
    itemValue: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    searchVal: '',
    textFieldSize: '185px',
  }),
  computed: {
  },
  watch: {
    itemValue: {
      handler() {
        this.searchVal = this.itemValue;
        this.computeWidth();
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    this.searchVal = '';
  },
  methods: {
    computeWidth() {
      this.$nextTick(() => {
        let ref = this.$refs.contentWidth;
        if (ref) {
          let width = ref.clientWidth;
          this.textFieldSize = (width ? width : 185) + 'px';
        } else {
          this.textFieldSize = '185px';
        }
      });
    },
    inputUpdate() {
      this.$emit('update', {
        value: this.searchVal,
      });
      this.computeWidth();
    },
  },
};
</script>